import React, { useState,useEffect } from 'react';

import MainMenu from './MainMenu';
import logo from '../images/logo-dark.png';
import gitlogo from '../images/git_logo.png';
const Navbar = () => {
	const [showBut, setShowBut] = useState(false);

	useEffect( ()=>{
			if(window.location.href.includes("/developer")){
				setShowBut(true);
			}
			else{
				setShowBut(false);
			}
	 });	 
							
	const baseURL = 'https://scanner.deepware.ai';

	const [mobileMenuVisible, setMobileMenuVisible] = useState(false)
	return (		
		<div>
			<header id="masthead" className="site-header has-inline-mobile-toggle" itemType="https://schema.org/WPHeader" itemScope>
				<div className="inside-header grid-container">
					<div className="site-logo">
						<a href={'https://deepware.ai'} title="Deepware" rel="home">
							<img className="header-image is-logo-image" alt="Deepware" src="https://deepware.ai/wp-content/uploads/2020/12/Deepware_logo_200px.png" title="Deepware" srcSet="https://deepware.ai/wp-content/uploads/2020/12/Deepware_logo_200px.png 1x, https://deepware.ai/wp-content/uploads/2020/12/Deepware_logo_200px.png 2x" width={200} height={40} />
						</a>
					</div>
					<nav id="mobile-menu-control-wrapper" className="main-navigation mobile-menu-control-wrapper" style={{ display: 'block' }}></nav>
					<nav id="site-navigation" className="has-sticky-branding main-navigation sub-menu-right stuckElement" itemType="https://schema.org/SiteNavigationElement" itemScope>
						<div className="inside-navigation grid-container">
							{showBut && <div id="primary-menu2" className="main-nav">
								<ul id="menu-main-menu2" className=" menu sf-menu">
									<li id="menu-item-13918" className="freedemo menu-item menu-item-type-custom menu-item-object-custom menu-item-13918">
										<a href={baseURL}>DEEPWARE SCANNER</a>
									</li>
								</ul>	 
							</div>}
							<div id="primary-menu" className="main-nav">								
								<ul id="menu-main-menu" className=" menu sf-menu">
									<li id="menu-item-13917" className="freedemo menu-item menu-item-type-custom menu-item-object-custom menu-item-13917">
										<a href="/developer">API</a>
									</li>
								</ul>
							</div>
							
							<div id="primary-menu" className="main-nav">								
								<ul id="menu-main-menu" className=" menu sf-menu">
									<li id="menu-item-139197" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13918">
										<a href="https://github.com/deepware/deepfake-scanner" target="_blank">
											<img border="0" alt="" src={gitlogo} width="60" height="70"/>

										</a>
									</li>
								</ul>
							</div>
							 
						</div>
					</nav>
				</div>
			</header>
			<nav id="mobile-header" data-auto-hide-sticky="&quot;true&quot;" className="main-navigation mobile-header-navigation do-overlay has-branding has-sticky-branding stuckElement" itemType="https://schema.org/SiteNavigationElement" itemScope>
				<div className="inside-navigation grid-container grid-parent">
					<div className="site-logo mobile-header-logo">
						<a href="https://deepware.ai/" title="Deepware" rel="home">
							<img src="https://deepware.ai/wp-content/uploads/2020/12/Deepware_logo_200px.png" alt="Deepware" className="is-logo-image" />
						</a>
					</div> 
					<button className="menu-toggle" aria-controls="mobile-menu" aria-expanded="false" onClick={() => setMobileMenuVisible(!mobileMenuVisible)}>
						<span className="gp-icon icon-menu-bars">
							<svg viewBox="0 0 512 512" aria-hidden="true" role="img" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1em" height="1em">
								<path d="M0 96c0-13.255 10.745-24 24-24h464c13.255 0 24 10.745 24 24s-10.745 24-24 24H24c-13.255 0-24-10.745-24-24zm0 160c0-13.255 10.745-24 24-24h464c13.255 0 24 10.745 24 24s-10.745 24-24 24H24c-13.255 0-24-10.745-24-24zm0 160c0-13.255 10.745-24 24-24h464c13.255 0 24 10.745 24 24s-10.745 24-24 24H24c-13.255 0-24-10.745-24-24z"></path>
							</svg>
							<svg viewBox="0 0 512 512" aria-hidden="true" role="img" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1em" height="1em">
								<path d="M71.029 71.029c9.373-9.372 24.569-9.372 33.942 0L256 222.059l151.029-151.03c9.373-9.372 24.569-9.372 33.942 0 9.372 9.373 9.372 24.569 0 33.942L289.941 256l151.03 151.029c9.372 9.373 9.372 24.569 0 33.942-9.373 9.372-24.569 9.372-33.942 0L256 289.941l-151.029 151.03c-9.373 9.372-24.569 9.372-33.942 0-9.372-9.373-9.372-24.569 0-33.942L222.059 256 71.029 104.971c-9.372-9.373-9.372-24.569 0-33.942z"></path>
							</svg>
						</span>
						<span className="screen-reader-text">Menu</span> 
					</button>
					<div id="mobile-menu" className="main-nav">
							<ul id="menu-main-menu-2" className=" menu sf-menu" style={{ display: (mobileMenuVisible ? 'block' : 'none') }}>
								<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-15239">
								</li><li className="freedemo menu-item menu-item-type-custom menu-item-object-custom menu-item-13917"><a href={baseURL}>DEEPWARE SCANNER</a></li>							
							</ul>
						</div>
						<div id="mobile-menu" className="main-nav">
							<ul id="menu-main-menu-1" className=" menu sf-menu" style={{ display: (mobileMenuVisible ? 'block' : 'none') }}>
								<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-15239">
								</li><li className="freedemo menu-item menu-item-type-custom menu-item-object-custom menu-item-13917"><a href="developer">API</a></li>							
							</ul>
						</div>
				</div>{/* .inside-navigation */}
			</nav>
			<nav id="generate-slideout-menu" className="main-navigation slideout-navigation do-overlay offside offside--left is-open" itemType="https://schema.org/SiteNavigationElement" itemScope style={{ display: (mobileMenuVisible ? 'block' : 'none') }}>
				<div className="inside-navigation grid-container grid-parent">
					<button className="slideout-exit has-svg-icon" onClick={() => setMobileMenuVisible(false)}>
						<span className="gp-icon pro-close">
							<svg viewBox="0 0 512 512" aria-hidden="true" role="img" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1em" height="1em">
								<path d="M71.029 71.029c9.373-9.372 24.569-9.372 33.942 0L256 222.059l151.029-151.03c9.373-9.372 24.569-9.372 33.942 0 9.372 9.373 9.372 24.569 0 33.942L289.941 256l151.03 151.029c9.372 9.373 9.372 24.569 0 33.942-9.373 9.372-24.569 9.372-33.942 0L256 289.941l-151.029 151.03c-9.373 9.372-24.569 9.372-33.942 0-9.372-9.373-9.372-24.569 0-33.942L222.059 256 71.029 104.971c-9.372-9.373-9.372-24.569 0-33.942z" />
							</svg>
						</span> 
						<span className="screen-reader-text">Close</span>
					</button>
					<div className="main-nav">
						<ul id="menu-footer-legal-pages-2" className=" slideout-menu">
							<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-195"><a href={baseURL}>DEEPWARE SCANNER</a></li>
						</ul>
						{/* <ul id="menu-footer-legal-pages-3" className=" slideout-menu">
							<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-193"><a href={baseURL}>Github</a></li>
						</ul> */}
						<ul id="menu-footer-legal-pages-1" className=" slideout-menu">
							<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-195"><a href="/developer">API</a></li>
						</ul>
					</div>			
				</div>{/* .inside-navigation */}
			</nav>
		</div>
	);
};

export default Navbar;
