import React from 'react';

const Footer = (props) => {
  return (
    <>
      <div className={props.fixed ? "fixed-desktop-footer site-footer footer-bar-active footer-bar-align-right" : "site-footer footer-bar-active footer-bar-align-right"}>
          <div id="footer-widgets" className="site footer-widgets">
            <div className="footer-widgets-container grid-container">
              <div className="inside-footer-widgets">
                <div className="footer-widget-1">
                  <aside id="media_image-6" className="widget inner-padding widget_media_image"><img width={2000} height={502} src="https://deepware.ai/wp-content/uploads/2021/01/lightcopy.png" className="image wp-image-15545  attachment-full size-full" alt="" loading="lazy" style={{maxWidth: '100%', height: 'auto'}} srcSet="https://deepware.ai/wp-content/uploads/2021/01/lightcopy.png 2000w, https://deepware.ai/wp-content/uploads/2021/01/lightcopy-450x113.png 450w, https://deepware.ai/wp-content/uploads/2021/01/lightcopy-1024x257.png 1024w, https://deepware.ai/wp-content/uploads/2021/01/lightcopy-768x193.png 768w, https://deepware.ai/wp-content/uploads/2021/01/lightcopy-1536x386.png 1536w" sizes="(max-width: 2000px) 100vw, 2000px" /></aside>
                </div>
                <div className="footer-widget-2">
                  <aside id="text-10" className="widget inner-padding widget_text">
                    <div className="textwidget">
                      <p className="has-text-align-center"><strong>COMPANY</strong></p>
                      <p className="has-text-align-center">
                        <a href="https://deepware.ai/about/">About Us</a><br />
                        <a href="https://deepware.ai/contact/">Contact Us</a><br />
                        <a href="https://deepware.ai/blog">Blog</a><br />
                        <a href="https://deepware.ai/faq">FAQ</a>
                      </p>
                    </div>
                  </aside>
                </div>
                <div className="footer-widget-3">
                  <aside id="text-9" className="widget inner-padding widget_text">
                    <div className="textwidget">
                      <p className="has-text-align-center"><strong>WHERE WE ARE</strong></p>
                      <p className="has-text-align-center">Bilkent Cyberpark Cankaya<br />
                        Ankara, Turkiye</p>
                      <p className="has-text-align-center"><a href="mailto:info@deepware.ai">info@deepware.ai</a><br />
                        <a href="tel:+90 312 219 5660">+90 (312) 219 5660</a>
                      </p>
                    </div>
                  </aside>
                </div>
                <div className="footer-widget-4">
                  <aside id="lsi_widget-1" className="widget inner-padding widget_lsi_widget">
                    <ul className="lsi-social-icons icon-set-lsi_widget-1" style={{textAlign: 'center'}}>
                      <li className="lsi-social-linkedin"><a rel="nofollow noopener noreferrer" title="LinkedIn" aria-label="LinkedIn" href="https://www.linkedin.com/company/deepwareai" target="_blank"><i className="lsicon lsicon-linkedin" /></a></li>
                      <li className="lsi-social-twitter"><a rel="nofollow noopener noreferrer" title="Twitter" aria-label="Twitter" href="https://twitter.com/deepwareai" target="_blank"><i className="lsicon lsicon-twitter" /></a></li>
                      <li className="lsi-social-facebook"><a rel="nofollow noopener noreferrer" title="Facebook" aria-label="Facebook" href="https://www.facebook.com/deepwareai" target="_blank"><i className="lsicon lsicon-facebook" /></a></li>
                      <li className="lsi-social-instagram"><a rel="nofollow noopener noreferrer" title="Instagram" aria-label="Instagram" href="https://www.instagram.com/accounts/login/?next=/deepwarelab/" target="_blank"><i className="lsicon lsicon-instagram" /></a></li>
                      <li className="lsi-social-youtube"><a rel="nofollow noopener noreferrer" title="YouTube" aria-label="YouTube" href="https://www.youtube.com/channel/UCZviS4sa7KTnk0iCQ78ePrg" target="_blank"><i className="lsicon lsicon-youtube" /></a></li>
                    </ul>
                  </aside>
                </div>
              </div>
            </div>
          </div>
          <footer className="site-info" itemType="https://schema.org/WPFooter" itemScope>
            <div className="inside-site-info grid-container">
              <div className="footer-bar">
                <aside id="nav_menu-1" className="widget inner-padding widget_nav_menu">
                  <div className="menu-footer-legal-pages-container">
                    <ul id="menu-footer-legal-pages" className="menu">
                      <li id="menu-item-195" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-195"><a href="https://deepware.ai/privacy-policy/">Privacy Policy</a></li>
                      <li id="menu-item-196" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-196"><a href="https://deepware.ai/terms-of-services/">Terms of Service</a></li>
                      <li id="menu-item-196" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-196"><a href="https://deepware.ai/release-note/">Release Notes</a></li>
                    </ul>
                  </div>
                </aside>
              </div>
              <div className="copyright-bar">
                deepware.ai ™ | © 2021 </div>
            </div>
          </footer>
        </div>
    </>
  );
};

export default Footer;
