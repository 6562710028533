import axios from 'axios';
import CryptoJS from 'crypto-js';

const intelligenceURL = '/public/intelligence';
const framesURL = '/public/frames/info';
const videoScanURL = '/public/video/scan';
const videoReportURL = '/public/video/report';
const ytScanURL = '/public/url/scan';
const ytReportURL = '/public/url/report';
const lastReportsURL = '/public/reports/last';
const videoURL = '/public/video/download';
const resultURL = '/result';
const sendMailURL = '/public/SendMail/Send';
//const ytURLRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([^"&?\/\s]{11})(?:\S+)?$/;
const ytURLRegex = /^(?:https?:\/\/)?(?:.*\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([^"&?\/\s]{11})(?:\S+)?$/;


export const statuses = {
  NOTFOUND: -1,
  LOADING: 0,
  ONQUEUE: 1,
  ENQUEUING: 2,
  SCANNING: 3,
  FAILED: 4,
  CLEAN: 5,
  DEEPFAKE: 6,
  SUSPICIOUS: 7,
  ERROR: 8,
};

export const colors = {
  BLUE: '#5dabf1',
  GREEN: '#00bd8e',
  RED: '#dd5454',
  YELLOW: '#e7af24',
};

const getColorbyStatus = (status) => {
  switch (status) {
    case statuses.DEEPFAKE:
      return colors.RED;
    case statuses.CLEAN:
      return colors.GREEN;
    case statuses.FAILED:
      return colors.YELLOW;
    case statuses.SUSPICIOUS:
      return colors.YELLOW;
    case statuses.ERROR:
      return colors.RED;
    default:
      return colors.BLUE;
  }
};

const getStatusByReport = (report, th1, th2, th3, th4) => {

  if (report?.completed === true) {

    if (report?.messages != undefined) {

      var messages = Object.keys(report?.messages);
      if (messages.length > 0) {
        if (report?.messages[0]?.type !== 0) {
          if (report?.messages[0]?.type !== undefined) {
            return statuses.ERROR;
          }
        }

      }
    }

    let avatarifyResult = "";
    let finalResult = "";
    let avatarifyExist = false;
    let finalResultExist = false;
    if (report?.results?.analyst != undefined) {

      if (report?.results?.analyst?.score < 0) {
        return statuses.FAILED;
      }
      if (0 <= report?.results?.analyst?.score && report?.results?.analyst?.score <= th1) {
        return statuses.CLEAN
      }
      if (th2 <= report?.results?.analyst?.score && report?.results?.analyst?.score <= th3) {
        return statuses.SUSPICIOUS;
      }
      if (th4 <= report?.results?.analyst?.score) {
        return statuses.DEEPFAKE;
      }

    }
    else if (report?.results?.Analyst != undefined) {

      if (report?.results?.Analyst?.score < 0) {
        return statuses.FAILED;
      }
      if (0 <= report?.results?.Analyst?.score && report?.results?.Analyst?.score <= th1) {
        return statuses.CLEAN
      }
      if (th2 <= report?.results?.Analyst?.score && report?.results?.Analyst?.score <= th3) {
        return statuses.SUSPICIOUS;
      }
      if (th4 <= report?.results?.Analyst?.score) {
        return statuses.DEEPFAKE;
      }
    }
     if (report?.results?.avatarify != undefined) {
      avatarifyExist = true;
      if (report?.results?.avatarify?.score < 0) {
        avatarifyResult = statuses.FAILED;
      }
      if (0 <= report?.results?.avatarify?.score && report?.results?.avatarify?.score <= th1) {
        avatarifyResult = statuses.CLEAN
      }
      if (th2 <= report?.results?.avatarify?.score && report?.results?.avatarify?.score <= th3) {
        avatarifyResult = statuses.SUSPICIOUS;
      }
      if (th4 <= report?.results?.avatarify?.score) {
        avatarifyResult = statuses.DEEPFAKE;
      }
    }
     if (report?.results?.final != undefined) {
      finalResultExist = true;
      if (report?.results?.final?.score < 0) {
        finalResult = statuses.FAILED;
      }
      if (0 <= report?.results?.final?.score && report?.results?.final?.score <= th1) {
        finalResult = statuses.CLEAN
      }
      if (th2 <= report?.results?.final?.score && report?.results?.final?.score <= th3) {
        finalResult = statuses.SUSPICIOUS;
      }
      if (th4 <= report?.results?.final?.score) {
        finalResult = statuses.DEEPFAKE;
      }
    }
    if (avatarifyExist == true || finalResultExist == true) {
      if (avatarifyResult == statuses.DEEPFAKE) {
        return statuses.DEEPFAKE;
      }
      if (finalResult == statuses.DEEPFAKE) {
        return statuses.DEEPFAKE;
      }
      if (avatarifyResult == statuses.SUSPICIOUS) {
        return statuses.SUSPICIOUS;
      }
      if (finalResult == statuses.SUSPICIOUS) {
        return statuses.SUSPICIOUS;
      }
      if (avatarifyExist == true) {
        return avatarifyResult;
      }
      if (finalResultExist == true) {
        return finalResult;
      }
    }
    else {
      if (report?.results?.deepware?.score < 0) {
        return statuses.FAILED;
      }
      if (0 <= report?.results?.deepware?.score && report?.results?.deepware?.score <= th1) {
        return statuses.CLEAN
      }
      if (th2 <= report?.results?.deepware?.score && report?.results?.deepware?.score <= th3) {
        return statuses.SUSPICIOUS;
      }
      if (th4 <= report?.results?.deepware?.score) {
        return statuses.DEEPFAKE;
      }
    }


  }

  //onqueue
  if (report?.queue > 1) {
    return statuses.ONQUEUE;
  }
  //enqueuing
  if (report?.queue === 1) {
    return statuses.ENQUEUING;
  }
  //scanning
  if (report?.queue <= 0) {
    return statuses.SCANNING;
  }

  return statuses.LOADING;
};



const getStatusByValue = (val, threshold) => {
  let th1 = 50
  let th2 = 51
  let th3 = 79
  let th4 = 80
  if (threshold != undefined) {
    th1 = threshold?.green[1];
    th2 = threshold?.yellow[0];
    th3 = threshold?.yellow[1];
    th4 = threshold?.red[0];
  }


  if (val < 0) {
    return statuses.FAILED;
  }

  if (0 <= val && val <= th1) {
    return statuses.CLEAN
  }

  if (th2 <= val && val <= th3) {
    return statuses.SUSPICIOUS;
  }
  if (th4 <= val) {
    return statuses.DEEPFAKE;
  }

}


const getStatusText = (status, report) => {


  switch (status) {
    case statuses.ONQUEUE:
      return `YOUR REQUEST ON QUEUE (${report?.queue})`;
    case statuses.ENQUEUING:
      return 'ENQUEUING VIDEO FOR SCAN...';
    case statuses.SCANNING:
      return 'SCANNING...';
    case statuses.DEEPFAKE:
      return 'DEEPFAKE DETECTED';
    case statuses.CLEAN:
      return 'NO DEEPFAKE DETECTED';

    case statuses.SUSPICIOUS:
      return 'SUSPICIOUS';

    case statuses.FAILED:
      return 'SCAN FAILED';

    case statuses.ERROR:
      return report?.messages[0]?.msg;
    default:
      return '';
  }
};

const scanVideo = (file, onSuccess, onInvalid, onError, onProgress) => {
  const data = new FormData();
  data.append('video', file);

  const options = {
    onUploadProgress: (e) => {
      const { loaded, total } = e;

      onProgress(Math.floor((loaded * 100) / total));
    },
    validateStatus: false,
  };

  axios
    .post(videoScanURL, data, options)
    .then(function (res) {
      if (res.status === 200) {
        onSuccess(res.data);
        return;
      }

      console.log(res);
      onInvalid(res);
    })
    .catch(function (err) {
      console.log(err);
      onError(err);
    });
};

const scanYT = (ytID, onSuccess, onInvalid, onError) => {
  axios
    .get(`${ytScanURL}?video-url=${ytID}`, { validateStatus: false })
    .then(function (res) {
      if (res.status === 200) {
        onSuccess(res.data);
        return;
      }

      console.log(res);
      onInvalid(res);
    })
    .catch(function (err) {
      console.log(err);
      onError(err);
    });
};

const getReport = (val, onSuccess, onInvalid, onError) => {
  let url = `${videoReportURL}?video-id=${val}`;

  if (isYoutubeID(val)) {
    url = `${ytReportURL}?video-url=${val}`;
  }

  if (isReportID(val)) {
    url = `${videoReportURL}?report-id=${val}`;
  }

  axios
    .get(url, { validateStatus: false })
    .then(function (res) {
      if (res.status === 200) {
        onSuccess(res.data);
        return;
      }

      console.log(res);
      onInvalid(res);
      return;
    })
    .catch(function (err) {
      console.log(err);
      onError(err);
    });
};

const getUrlVideoReport = (val, onSuccess, onInvalid, onError) => {
  let url = `${ytReportURL}?video-url=${val}`;

  axios
    .get(url, { validateStatus: false })
    .then(function (res) {
      if (res.status === 200) {
        onSuccess(res.data);
        return;
      }

      console.log(res);
      onInvalid(res);
      return;
    })
    .catch(function (err) {
      console.log(err);
      onError(err);
    });
};


const getInfo = (videoID, onSuccess, onError) => {
  axios
    .get(`${intelligenceURL}/info/${videoID}`)
    .then(function (resp) {
      if (resp.status === 200) {
        onSuccess(resp.data);
        return;
      }

      console.log(resp);
      onError(resp);
    })
    .catch(function (err) {
      console.log(err);
      onError(err);
    });
};

const getVideos = (faceID, onSuccess, onError) => {
  axios
    .get(`${intelligenceURL}/face/to/video/${faceID}`)
    .then(function (resp) {
      if (resp.status === 200) {
        onSuccess(resp.data);
        return;
      }

      console.log(resp);
      onError(resp);
    })
    .catch(function (err) {
      console.log(err);
      onError(err);
    });
};

const getLastReports = (onSuccess, onError) => {
  axios
    .get(`${lastReportsURL}`)
    .then(function (resp) {
      if (resp.status === 200) {
        onSuccess(resp.data);
        return;
      }

      console.log(resp);
      onError();
    })
    .catch(function (err) {
      console.log(err);
      onError(err);
    });
};

const getFaceThumbnailURL = (videoID, index) => {
  return `${intelligenceURL}/face/thumbnail/${videoID}/${index}`;
};

const getFaceVideoURL = (videoID, index) => {
  return `${intelligenceURL}/face/video/${videoID}/${index}`;
};

const getVideoThumbnailURL = (videoID) => {
  return `${intelligenceURL}/thumbnail/${videoID}`;
};

const getVideoURL = (videoID) => {
  return `${videoURL}/${videoID}`;
};

const getResultURL = (reportID) => {
  return `${resultURL}/${reportID}`;
};

const getReportID = () => {
  var reportID = window.location.pathname;
  if (reportID.indexOf('/') === 0) {
    reportID = reportID.substring(1);
  }

  return isReportID(reportID) ? reportID : null;
};

const isYT = (url) => {
  if (url && url.indexOf('https://youtu.be') === 0) {
    return true;
  }

  return false;
};

const getTimeStamp = (reportID) => {
  if (!reportID) {
    return null;
  }

  const parts = reportID.split('-');

  if (parts.length < 2) {
    return null;
  }

  return reportID.split('-')[1];
};

const submittedOn = (reportID) => {
  const timestamp = getTimeStamp(reportID);

  if (!timestamp) {
    return '-';
  }

  const date = new Date(timestamp * 1000);
  const year = date.getUTCFullYear();
  const month = ('0' + (date.getUTCMonth() + 1)).substr(-2);
  const day = ('0' + date.getUTCDate()).substr(-2);
  const hours = ('0' + date.getUTCHours()).substr(-2);
  const minutes = ('0' + date.getUTCMinutes()).substr(-2);
  const seconds = ('0' + date.getUTCSeconds()).substr(-2);

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} UTC`;
};

const submittedTimeAgo = (reportID) => {
  const timestamp = getTimeStamp(reportID);

  if (!timestamp) {
    return '-';
  }

  const date = new Date(timestamp * 1000);
  const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
  const today = new Date();
  const yesterday = new Date(today - DAY_IN_MS);
  const seconds = Math.round((today - date) / 1000);
  const minutes = Math.round(seconds / 60);
  const hours = Math.round(minutes / 60);
  const days = Math.round(hours / 24);
  const months = Math.round(days / 30);
  const years = Math.round(days / 365);
  const isYesterday = yesterday.toDateString() === date.toDateString();

  if (seconds < 5) {
    return 'now';
  } else if (seconds < 60) {
    return `${seconds} seconds ago`;
  } else if (seconds < 90) {
    return 'about a minute ago';
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else if (isYesterday) {
    return 'Yesterday';
  } else if (days < 30) {
    return `${days} day(s) ago`;
  } else if (months < 12) {
    return `${months} month(s) ago`;
  } else if (years < 12) {
    return `${years} year(s) ago`;
  }

  return '-';
};

const fmtSec = (sec) => {
  if (!sec) {
    return '-';
  }
  return `${(sec / 1).toFixed(0)} sec`;
};

const fmtFrameRate = (rate) => {
  if (!rate) {
    return '-';
  }

  return `${Math.floor(rate * 100) / 100} fps`;
};

const fmtSampleRate = (rate) => {
  if (!rate) {
    return '-';
  }
  return `${(rate / 1000).toFixed(0)} khz`;
};

const resolution = (width, height) => {
  if (!width || !height) {
    return '-';
  }
  return `${width} x ${height}`;
};

function videoSize(bytes, decimals = 1) {
  if (bytes === 0) return '-';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function calcSha1(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = function (f) {
      // this == reader, get the loaded file "result"
      let arrBuffer = this.result;

      //convert blob to WordArray , see https://code.google.com/p/crypto-js/issues/detail?id=67
      let file_wordArr = CryptoJS.lib.WordArray.create(arrBuffer);

      //calculate SHA1 hash
      let sha1 = CryptoJS.SHA1(file_wordArr).toString();

      if (sha1) {
        resolve(sha1);
        return;
      }
      reject('Error');
    };

    reader.readAsArrayBuffer(file);
  });
}

function isReportID(val) {
  return new RegExp(/\b([a-f0-9]{40})-([0-9]{10})\b/gi).test(val);
}

function isYoutubeID(val) {
  return new RegExp(/^([^"&?\/\s]{11})$/).test(val);
}

function getYoutubeID(url) {
  if (url != undefined || url != '') {
    var matches = url.match(ytURLRegex);
    if (matches) {
      return matches[1];
    }
    return null;
  }
  return null;
}


const getFrames = (reportId, onSuccess) => {
  const segments = reportId.split('-')
  axios
    .get(`${framesURL}/${segments[0]}`)
    .then(function (resp) {
      if (resp.status === 200) {
        onSuccess(resp.data);
        return;
      }
      console.log(resp);
    })
    .catch(function (err) {
      console.log(err);
    });
};

const sendRequestMail = (mailType, ReportId, UserEmail, captchaResponse, reason, callback) => {
  let url = `${sendMailURL}`;
  var bodyFormData = new FormData();

  bodyFormData.append('ReportId', ReportId);
  bodyFormData.append('UserEmail', UserEmail);
  bodyFormData.append('MailType', mailType);
  bodyFormData.append('Response', captchaResponse);
  bodyFormData.append('Reason', reason);

  axios
    .post(url, bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(function (res) {
      if (res.status === 200) {
        callback(true, "")
        return;
      }
      callback(false, res.data)
    })
    .catch(function (err) {
      console.log(err);
      callback(false, err)
    });
};

export default {
  getColorbyStatus,
  getStatusByReport,
  getStatusText,
  scanVideo,
  scanYT,
  getReport,
  getUrlVideoReport,
  getInfo,
  getVideos,
  getLastReports,
  getFaceThumbnailURL,
  getFaceVideoURL,
  getVideoThumbnailURL,
  getVideoURL,
  getResultURL,
  getReportID,
  isYT,
  submittedOn,
  submittedTimeAgo,
  fmtSec,
  fmtFrameRate,
  fmtSampleRate,
  resolution,
  videoSize,
  calcSha1,
  getYoutubeID,
  getFrames, sendRequestMail, getStatusByValue
};
