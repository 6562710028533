import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Navbar from './Navbar';
import './Layout.css';

const Layout = ({ children }) => {
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    if (window.location.pathname.indexOf('/result') > 0) {
      setShowFooter(true);
    }
  }, []);
  return (
    <>
      <Navbar />
      <main>{children}</main>
      {showFooter && (
        <footer>
          © {new Date().getFullYear()}, Built by
          {` `}
          <a href="https://www.zemana.com">Zemana</a>
        </footer>
      )}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
